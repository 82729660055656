@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700|Poppins:400,500,700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
}

html,
body {
  overflow-x: hidden;
  height: 100%;
  // background: black;
}

.link-no-style {
  text-decoration: none;
}