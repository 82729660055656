.main-banner {
  width: 100vw;
  padding: 150px 60px 250px;

  color: white;

  position: relative;
  justify-content: space-between;
  align-items: center;

  .header-app-name {
    font-family: 'Open Sans', Helvetica, sans-serif;
    font-weight: bold;
    font-size: 24px;
    
    position: absolute;
    top: 50px;
    left: 60px;
  }
  
  .header-content {
    width: 40%;
    z-index: 20;
    
    .download-buttons {
      margin-top: 50px;
      align-items: center;
      
      .header-btn {
        width: 190px;
        justify-content: space-around;
        align-items: center;
        padding: 10px;
        border-radius: 28px 28px 28px 0;
        position: relative;
        margin-right: 50px;
      }
      
      p {
        font-size: 12px;
      }
      
      .store {
        font-size: 16px;
        font-weight: 500;
        display: block;
      }
    }
  }
  
  .header-main-title {
    font-family: 'Poppins', Helvetica, sans-serif;
    font-weight: bold;
    font-size: 60px;
  }
  
  .header-img {
    width: 60%;
    z-index: 25;
    position: relative;
    
    img {
      position: absolute;
      width: 100%;
      top: -250px;
    }
  }

  .header-main-paragraph {
    margin-top: 30px;
    font-family: 'Poppins', Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
  }
  
  .rounds-back {
    position: absolute;
    left: 500px;
    top: -321.46px;
  }
  
  .rounds-back-2 {
    position: absolute;
    left: 0;
    top: 371.46px;
  }
}