@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700|Poppins:400,500,700&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', Arial, Helvetica, sans-serif; }

html,
body {
  overflow-x: hidden;
  height: 100%; }

.link-no-style {
  text-decoration: none; }

.d-flex {
  display: flex; }

.bg-purple-gradient {
  background: -webkit-linear-gradient(322.77deg, #812ACF 3.68%, #8162E9 77.66%);
  background: linear-gradient(127.23deg, #812ACF 3.68%, #8162E9 77.66%); }

.bg-pink {
  background: #FB397D;
  color: #fff; }

.bg-white {
  background: white;
  color: #47425D; }

.main-banner {
  width: 100vw;
  padding: 150px 60px 250px;
  color: white;
  position: relative;
  justify-content: space-between;
  align-items: center; }
  .main-banner .header-app-name {
    font-family: 'Open Sans', Helvetica, sans-serif;
    font-weight: bold;
    font-size: 24px;
    position: absolute;
    top: 50px;
    left: 60px; }
  .main-banner .header-content {
    width: 40%;
    z-index: 20; }
    .main-banner .header-content .download-buttons {
      margin-top: 50px;
      align-items: center; }
      .main-banner .header-content .download-buttons .header-btn {
        width: 190px;
        justify-content: space-around;
        align-items: center;
        padding: 10px;
        border-radius: 28px 28px 28px 0;
        position: relative;
        margin-right: 50px; }
      .main-banner .header-content .download-buttons p {
        font-size: 12px; }
      .main-banner .header-content .download-buttons .store {
        font-size: 16px;
        font-weight: 500;
        display: block; }
  .main-banner .header-main-title {
    font-family: 'Poppins', Helvetica, sans-serif;
    font-weight: bold;
    font-size: 60px; }
  .main-banner .header-img {
    width: 60%;
    z-index: 25;
    position: relative; }
    .main-banner .header-img img {
      position: absolute;
      width: 100%;
      top: -250px; }
  .main-banner .header-main-paragraph {
    margin-top: 30px;
    font-family: 'Poppins', Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px; }
  .main-banner .rounds-back {
    position: absolute;
    left: 500px;
    top: -321.46px; }
  .main-banner .rounds-back-2 {
    position: absolute;
    left: 0;
    top: 371.46px; }

.features {
  padding: 10px 60px;
  position: relative;
  z-index: 10; }
  .features__title {
    color: #5B32B4;
    font-size: 48px;
    text-align: center;
    position: relative;
    justify-content: center;
    margin-bottom: 50px; }
    .features__title::after {
      position: absolute;
      content: "";
      border-top: 2px #FB397D solid;
      bottom: 0;
      width: 90px; }
  .features__bg-1, .features__bg-2, .features__bg-3, .features__bg-4, .features__bg-5 {
    position: absolute;
    right: -5px; }
  .features__bg-1 {
    top: -300px; }
  .features__bg-2 {
    top: -340px; }
  .features__bg-3 {
    top: -380px; }
  .features__bg-4 {
    bottom: -323px; }
  .features__bg-5 {
    bottom: -343px; }
  .features__container {
    justify-content: space-around;
    align-items: center; }
    .features__container__card {
      z-index: 30;
      width: 300px;
      height: 230px;
      border: 1px solid #EFF2F6;
      border-radius: 20px 20px 20px 0;
      -webkit-transition: .5s ease;
      transition: .5s ease;
      align-items: center;
      flex-direction: column;
      padding: 20px; }
      .features__container__card:hover {
        box-shadow: 0px 0px 50px rgba(36, 36, 36, 0.08); }
      .features__container__card .icon {
        margin-bottom: 5px; }
      .features__container__card .name {
        margin-bottom: 15px;
        font-size: 18px;
        font-weight: normal;
        color: #FB397D;
        text-align: center; }
      .features__container__card .description {
        color: #726A84;
        font-size: 16px;
        text-align: center; }

@-webkit-keyframes from-0-to-1 {
  from {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); } }

@keyframes from-0-to-1 {
  from {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); } }

@-webkit-keyframes from-0-to-2 {
  from {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); } }

@keyframes from-0-to-2 {
  from {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); } }

@-webkit-keyframes from-0-to-3 {
  from {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); } }

@keyframes from-0-to-3 {
  from {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); } }

@-webkit-keyframes from-0-to-4 {
  from {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); } }

@keyframes from-0-to-4 {
  from {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); } }

@-webkit-keyframes from-0-to-5 {
  from {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); } }

@keyframes from-0-to-5 {
  from {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); } }

@-webkit-keyframes from-0-to-6 {
  from {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); } }

@keyframes from-0-to-6 {
  from {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); } }

@-webkit-keyframes from-0-to-7 {
  from {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); } }

@keyframes from-0-to-7 {
  from {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); } }

@-webkit-keyframes from-0-to-8 {
  from {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); } }

@keyframes from-0-to-8 {
  from {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); } }

@-webkit-keyframes from-1-to-0 {
  from {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); } }

@keyframes from-1-to-0 {
  from {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); } }

@-webkit-keyframes from-1-to-2 {
  from {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); } }

@keyframes from-1-to-2 {
  from {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); } }

@-webkit-keyframes from-1-to-3 {
  from {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); } }

@keyframes from-1-to-3 {
  from {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); } }

@-webkit-keyframes from-1-to-4 {
  from {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); } }

@keyframes from-1-to-4 {
  from {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); } }

@-webkit-keyframes from-1-to-5 {
  from {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); } }

@keyframes from-1-to-5 {
  from {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); } }

@-webkit-keyframes from-1-to-6 {
  from {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); } }

@keyframes from-1-to-6 {
  from {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); } }

@-webkit-keyframes from-1-to-7 {
  from {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); } }

@keyframes from-1-to-7 {
  from {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); } }

@-webkit-keyframes from-1-to-8 {
  from {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); } }

@keyframes from-1-to-8 {
  from {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); } }

@-webkit-keyframes from-2-to-0 {
  from {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); } }

@keyframes from-2-to-0 {
  from {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); } }

@-webkit-keyframes from-2-to-1 {
  from {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); } }

@keyframes from-2-to-1 {
  from {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); } }

@-webkit-keyframes from-2-to-3 {
  from {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); } }

@keyframes from-2-to-3 {
  from {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); } }

@-webkit-keyframes from-2-to-4 {
  from {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); } }

@keyframes from-2-to-4 {
  from {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); } }

@-webkit-keyframes from-2-to-5 {
  from {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); } }

@keyframes from-2-to-5 {
  from {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); } }

@-webkit-keyframes from-2-to-6 {
  from {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); } }

@keyframes from-2-to-6 {
  from {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); } }

@-webkit-keyframes from-2-to-7 {
  from {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); } }

@keyframes from-2-to-7 {
  from {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); } }

@-webkit-keyframes from-2-to-8 {
  from {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); } }

@keyframes from-2-to-8 {
  from {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); } }

@-webkit-keyframes from-3-to-0 {
  from {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); } }

@keyframes from-3-to-0 {
  from {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); } }

@-webkit-keyframes from-3-to-1 {
  from {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); } }

@keyframes from-3-to-1 {
  from {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); } }

@-webkit-keyframes from-3-to-2 {
  from {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); } }

@keyframes from-3-to-2 {
  from {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); } }

@-webkit-keyframes from-3-to-4 {
  from {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); } }

@keyframes from-3-to-4 {
  from {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); } }

@-webkit-keyframes from-3-to-5 {
  from {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); } }

@keyframes from-3-to-5 {
  from {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); } }

@-webkit-keyframes from-3-to-6 {
  from {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); } }

@keyframes from-3-to-6 {
  from {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); } }

@-webkit-keyframes from-3-to-7 {
  from {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); } }

@keyframes from-3-to-7 {
  from {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); } }

@-webkit-keyframes from-3-to-8 {
  from {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); } }

@keyframes from-3-to-8 {
  from {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); } }

@-webkit-keyframes from-4-to-0 {
  from {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); } }

@keyframes from-4-to-0 {
  from {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); } }

@-webkit-keyframes from-4-to-1 {
  from {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); } }

@keyframes from-4-to-1 {
  from {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); } }

@-webkit-keyframes from-4-to-2 {
  from {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); } }

@keyframes from-4-to-2 {
  from {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); } }

@-webkit-keyframes from-4-to-3 {
  from {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); } }

@keyframes from-4-to-3 {
  from {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); } }

@-webkit-keyframes from-4-to-5 {
  from {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); } }

@keyframes from-4-to-5 {
  from {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); } }

@-webkit-keyframes from-4-to-6 {
  from {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); } }

@keyframes from-4-to-6 {
  from {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); } }

@-webkit-keyframes from-4-to-7 {
  from {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); } }

@keyframes from-4-to-7 {
  from {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); } }

@-webkit-keyframes from-4-to-8 {
  from {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); } }

@keyframes from-4-to-8 {
  from {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); } }

@-webkit-keyframes from-5-to-0 {
  from {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); } }

@keyframes from-5-to-0 {
  from {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); } }

@-webkit-keyframes from-5-to-1 {
  from {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); } }

@keyframes from-5-to-1 {
  from {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); } }

@-webkit-keyframes from-5-to-2 {
  from {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); } }

@keyframes from-5-to-2 {
  from {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); } }

@-webkit-keyframes from-5-to-3 {
  from {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); } }

@keyframes from-5-to-3 {
  from {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); } }

@-webkit-keyframes from-5-to-4 {
  from {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); } }

@keyframes from-5-to-4 {
  from {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); } }

@-webkit-keyframes from-5-to-6 {
  from {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); } }

@keyframes from-5-to-6 {
  from {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); } }

@-webkit-keyframes from-5-to-7 {
  from {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); } }

@keyframes from-5-to-7 {
  from {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); } }

@-webkit-keyframes from-5-to-8 {
  from {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); } }

@keyframes from-5-to-8 {
  from {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); } }

@-webkit-keyframes from-6-to-0 {
  from {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); } }

@keyframes from-6-to-0 {
  from {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); } }

@-webkit-keyframes from-6-to-1 {
  from {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); } }

@keyframes from-6-to-1 {
  from {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); } }

@-webkit-keyframes from-6-to-2 {
  from {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); } }

@keyframes from-6-to-2 {
  from {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); } }

@-webkit-keyframes from-6-to-3 {
  from {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); } }

@keyframes from-6-to-3 {
  from {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); } }

@-webkit-keyframes from-6-to-4 {
  from {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); } }

@keyframes from-6-to-4 {
  from {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); } }

@-webkit-keyframes from-6-to-5 {
  from {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); } }

@keyframes from-6-to-5 {
  from {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); } }

@-webkit-keyframes from-6-to-7 {
  from {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); } }

@keyframes from-6-to-7 {
  from {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); } }

@-webkit-keyframes from-6-to-8 {
  from {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); } }

@keyframes from-6-to-8 {
  from {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); } }

@-webkit-keyframes from-7-to-0 {
  from {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); } }

@keyframes from-7-to-0 {
  from {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); } }

@-webkit-keyframes from-7-to-1 {
  from {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); } }

@keyframes from-7-to-1 {
  from {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); } }

@-webkit-keyframes from-7-to-2 {
  from {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); } }

@keyframes from-7-to-2 {
  from {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); } }

@-webkit-keyframes from-7-to-3 {
  from {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); } }

@keyframes from-7-to-3 {
  from {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); } }

@-webkit-keyframes from-7-to-4 {
  from {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); } }

@keyframes from-7-to-4 {
  from {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); } }

@-webkit-keyframes from-7-to-5 {
  from {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); } }

@keyframes from-7-to-5 {
  from {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); } }

@-webkit-keyframes from-7-to-6 {
  from {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); } }

@keyframes from-7-to-6 {
  from {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); } }

@-webkit-keyframes from-7-to-8 {
  from {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); } }

@keyframes from-7-to-8 {
  from {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); } }

@-webkit-keyframes from-8-to-0 {
  from {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); } }

@keyframes from-8-to-0 {
  from {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (0 * (322px)) + 7px));
            transform: translateX(calc(1280px - (0 * (322px)) + 7px)); } }

@-webkit-keyframes from-8-to-1 {
  from {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); } }

@keyframes from-8-to-1 {
  from {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (1 * (322px)) + 7px));
            transform: translateX(calc(1280px - (1 * (322px)) + 7px)); } }

@-webkit-keyframes from-8-to-2 {
  from {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); } }

@keyframes from-8-to-2 {
  from {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (2 * (322px)) + 7px));
            transform: translateX(calc(1280px - (2 * (322px)) + 7px)); } }

@-webkit-keyframes from-8-to-3 {
  from {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); } }

@keyframes from-8-to-3 {
  from {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (3 * (322px)) + 7px));
            transform: translateX(calc(1280px - (3 * (322px)) + 7px)); } }

@-webkit-keyframes from-8-to-4 {
  from {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); } }

@keyframes from-8-to-4 {
  from {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (4 * (322px)) + 7px));
            transform: translateX(calc(1280px - (4 * (322px)) + 7px)); } }

@-webkit-keyframes from-8-to-5 {
  from {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); } }

@keyframes from-8-to-5 {
  from {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (5 * (322px)) + 7px));
            transform: translateX(calc(1280px - (5 * (322px)) + 7px)); } }

@-webkit-keyframes from-8-to-6 {
  from {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); } }

@keyframes from-8-to-6 {
  from {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (6 * (322px)) + 7px));
            transform: translateX(calc(1280px - (6 * (322px)) + 7px)); } }

@-webkit-keyframes from-8-to-7 {
  from {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); } }

@keyframes from-8-to-7 {
  from {
    -webkit-transform: translateX(calc(1280px - (8 * (322px)) + 7px));
            transform: translateX(calc(1280px - (8 * (322px)) + 7px)); }
  to {
    -webkit-transform: translateX(calc(1280px - (7 * (322px)) + 7px));
            transform: translateX(calc(1280px - (7 * (322px)) + 7px)); } }

@-webkit-keyframes from-0-to-1-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); } }

@keyframes from-0-to-1-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); } }

@-webkit-keyframes from-0-to-2-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); } }

@keyframes from-0-to-2-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); } }

@-webkit-keyframes from-0-to-3-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); } }

@keyframes from-0-to-3-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); } }

@-webkit-keyframes from-0-to-4-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); } }

@keyframes from-0-to-4-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); } }

@-webkit-keyframes from-0-to-5-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); } }

@keyframes from-0-to-5-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); } }

@-webkit-keyframes from-0-to-6-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); } }

@keyframes from-0-to-6-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); } }

@-webkit-keyframes from-0-to-7-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); } }

@keyframes from-0-to-7-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); } }

@-webkit-keyframes from-0-to-8-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); } }

@keyframes from-0-to-8-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); } }

@-webkit-keyframes from-1-to-0-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); } }

@keyframes from-1-to-0-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); } }

@-webkit-keyframes from-1-to-2-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); } }

@keyframes from-1-to-2-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); } }

@-webkit-keyframes from-1-to-3-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); } }

@keyframes from-1-to-3-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); } }

@-webkit-keyframes from-1-to-4-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); } }

@keyframes from-1-to-4-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); } }

@-webkit-keyframes from-1-to-5-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); } }

@keyframes from-1-to-5-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); } }

@-webkit-keyframes from-1-to-6-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); } }

@keyframes from-1-to-6-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); } }

@-webkit-keyframes from-1-to-7-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); } }

@keyframes from-1-to-7-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); } }

@-webkit-keyframes from-1-to-8-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); } }

@keyframes from-1-to-8-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); } }

@-webkit-keyframes from-2-to-0-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); } }

@keyframes from-2-to-0-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); } }

@-webkit-keyframes from-2-to-1-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); } }

@keyframes from-2-to-1-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); } }

@-webkit-keyframes from-2-to-3-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); } }

@keyframes from-2-to-3-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); } }

@-webkit-keyframes from-2-to-4-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); } }

@keyframes from-2-to-4-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); } }

@-webkit-keyframes from-2-to-5-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); } }

@keyframes from-2-to-5-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); } }

@-webkit-keyframes from-2-to-6-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); } }

@keyframes from-2-to-6-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); } }

@-webkit-keyframes from-2-to-7-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); } }

@keyframes from-2-to-7-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); } }

@-webkit-keyframes from-2-to-8-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); } }

@keyframes from-2-to-8-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); } }

@-webkit-keyframes from-3-to-0-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); } }

@keyframes from-3-to-0-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); } }

@-webkit-keyframes from-3-to-1-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); } }

@keyframes from-3-to-1-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); } }

@-webkit-keyframes from-3-to-2-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); } }

@keyframes from-3-to-2-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); } }

@-webkit-keyframes from-3-to-4-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); } }

@keyframes from-3-to-4-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); } }

@-webkit-keyframes from-3-to-5-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); } }

@keyframes from-3-to-5-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); } }

@-webkit-keyframes from-3-to-6-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); } }

@keyframes from-3-to-6-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); } }

@-webkit-keyframes from-3-to-7-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); } }

@keyframes from-3-to-7-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); } }

@-webkit-keyframes from-3-to-8-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); } }

@keyframes from-3-to-8-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); } }

@-webkit-keyframes from-4-to-0-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); } }

@keyframes from-4-to-0-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); } }

@-webkit-keyframes from-4-to-1-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); } }

@keyframes from-4-to-1-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); } }

@-webkit-keyframes from-4-to-2-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); } }

@keyframes from-4-to-2-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); } }

@-webkit-keyframes from-4-to-3-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); } }

@keyframes from-4-to-3-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); } }

@-webkit-keyframes from-4-to-5-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); } }

@keyframes from-4-to-5-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); } }

@-webkit-keyframes from-4-to-6-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); } }

@keyframes from-4-to-6-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); } }

@-webkit-keyframes from-4-to-7-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); } }

@keyframes from-4-to-7-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); } }

@-webkit-keyframes from-4-to-8-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); } }

@keyframes from-4-to-8-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); } }

@-webkit-keyframes from-5-to-0-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); } }

@keyframes from-5-to-0-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); } }

@-webkit-keyframes from-5-to-1-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); } }

@keyframes from-5-to-1-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); } }

@-webkit-keyframes from-5-to-2-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); } }

@keyframes from-5-to-2-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); } }

@-webkit-keyframes from-5-to-3-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); } }

@keyframes from-5-to-3-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); } }

@-webkit-keyframes from-5-to-4-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); } }

@keyframes from-5-to-4-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); } }

@-webkit-keyframes from-5-to-6-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); } }

@keyframes from-5-to-6-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); } }

@-webkit-keyframes from-5-to-7-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); } }

@keyframes from-5-to-7-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); } }

@-webkit-keyframes from-5-to-8-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); } }

@keyframes from-5-to-8-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); } }

@-webkit-keyframes from-6-to-0-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); } }

@keyframes from-6-to-0-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); } }

@-webkit-keyframes from-6-to-1-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); } }

@keyframes from-6-to-1-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); } }

@-webkit-keyframes from-6-to-2-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); } }

@keyframes from-6-to-2-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); } }

@-webkit-keyframes from-6-to-3-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); } }

@keyframes from-6-to-3-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); } }

@-webkit-keyframes from-6-to-4-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); } }

@keyframes from-6-to-4-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); } }

@-webkit-keyframes from-6-to-5-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); } }

@keyframes from-6-to-5-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); } }

@-webkit-keyframes from-6-to-7-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); } }

@keyframes from-6-to-7-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); } }

@-webkit-keyframes from-6-to-8-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); } }

@keyframes from-6-to-8-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); } }

@-webkit-keyframes from-7-to-0-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); } }

@keyframes from-7-to-0-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); } }

@-webkit-keyframes from-7-to-1-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); } }

@keyframes from-7-to-1-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); } }

@-webkit-keyframes from-7-to-2-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); } }

@keyframes from-7-to-2-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); } }

@-webkit-keyframes from-7-to-3-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); } }

@keyframes from-7-to-3-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); } }

@-webkit-keyframes from-7-to-4-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); } }

@keyframes from-7-to-4-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); } }

@-webkit-keyframes from-7-to-5-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); } }

@keyframes from-7-to-5-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); } }

@-webkit-keyframes from-7-to-6-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); } }

@keyframes from-7-to-6-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); } }

@-webkit-keyframes from-7-to-8-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); } }

@keyframes from-7-to-8-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); } }

@-webkit-keyframes from-8-to-0-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); } }

@keyframes from-8-to-0-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (0 * (254px))));
            transform: translateX(calc(1016px - (0 * (254px)))); } }

@-webkit-keyframes from-8-to-1-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); } }

@keyframes from-8-to-1-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (1 * (254px))));
            transform: translateX(calc(1016px - (1 * (254px)))); } }

@-webkit-keyframes from-8-to-2-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); } }

@keyframes from-8-to-2-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (2 * (254px))));
            transform: translateX(calc(1016px - (2 * (254px)))); } }

@-webkit-keyframes from-8-to-3-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); } }

@keyframes from-8-to-3-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (3 * (254px))));
            transform: translateX(calc(1016px - (3 * (254px)))); } }

@-webkit-keyframes from-8-to-4-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); } }

@keyframes from-8-to-4-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (4 * (254px))));
            transform: translateX(calc(1016px - (4 * (254px)))); } }

@-webkit-keyframes from-8-to-5-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); } }

@keyframes from-8-to-5-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (5 * (254px))));
            transform: translateX(calc(1016px - (5 * (254px)))); } }

@-webkit-keyframes from-8-to-6-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); } }

@keyframes from-8-to-6-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (6 * (254px))));
            transform: translateX(calc(1016px - (6 * (254px)))); } }

@-webkit-keyframes from-8-to-7-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); } }

@keyframes from-8-to-7-mob {
  from {
    -webkit-transform: translateX(calc(1016px - (8 * (254px))));
            transform: translateX(calc(1016px - (8 * (254px)))); }
  to {
    -webkit-transform: translateX(calc(1016px - (7 * (254px))));
            transform: translateX(calc(1016px - (7 * (254px)))); } }

.slides {
  background: -webkit-linear-gradient(19.64deg, #581EB7 -23.4%, #8160E8 90.51%);
  background: linear-gradient(70.36deg, #581EB7 -23.4%, #8160E8 90.51%);
  padding: 250px 0 400px;
  justify-content: center;
  align-items: center;
  z-index: 5; }
  .slides__container {
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .slides .preview-container {
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 90; }
    .slides .preview-container .preview-images {
      float: left;
      width: calc(262px * 9 + 60px * 9);
      -webkit-transform: translateX(calc(262px * 4 + 60px * 4));
              transform: translateX(calc(262px * 4 + 60px * 4)); }
      .slides .preview-container .preview-images.from-0-to-1 {
        -webkit-animation-name: from-0-to-1;
                animation-name: from-0-to-1;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-0-to-1 {
            -webkit-animation-name: from-0-to-1-mob;
                    animation-name: from-0-to-1-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-0-to-2 {
        -webkit-animation-name: from-0-to-2;
                animation-name: from-0-to-2;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-0-to-2 {
            -webkit-animation-name: from-0-to-2-mob;
                    animation-name: from-0-to-2-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-0-to-3 {
        -webkit-animation-name: from-0-to-3;
                animation-name: from-0-to-3;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-0-to-3 {
            -webkit-animation-name: from-0-to-3-mob;
                    animation-name: from-0-to-3-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-0-to-4 {
        -webkit-animation-name: from-0-to-4;
                animation-name: from-0-to-4;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-0-to-4 {
            -webkit-animation-name: from-0-to-4-mob;
                    animation-name: from-0-to-4-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-0-to-5 {
        -webkit-animation-name: from-0-to-5;
                animation-name: from-0-to-5;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-0-to-5 {
            -webkit-animation-name: from-0-to-5-mob;
                    animation-name: from-0-to-5-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-0-to-6 {
        -webkit-animation-name: from-0-to-6;
                animation-name: from-0-to-6;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-0-to-6 {
            -webkit-animation-name: from-0-to-6-mob;
                    animation-name: from-0-to-6-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-0-to-7 {
        -webkit-animation-name: from-0-to-7;
                animation-name: from-0-to-7;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-0-to-7 {
            -webkit-animation-name: from-0-to-7-mob;
                    animation-name: from-0-to-7-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-0-to-8 {
        -webkit-animation-name: from-0-to-8;
                animation-name: from-0-to-8;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-0-to-8 {
            -webkit-animation-name: from-0-to-8-mob;
                    animation-name: from-0-to-8-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-1-to-0 {
        -webkit-animation-name: from-1-to-0;
                animation-name: from-1-to-0;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-1-to-0 {
            -webkit-animation-name: from-1-to-0-mob;
                    animation-name: from-1-to-0-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-1-to-2 {
        -webkit-animation-name: from-1-to-2;
                animation-name: from-1-to-2;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-1-to-2 {
            -webkit-animation-name: from-1-to-2-mob;
                    animation-name: from-1-to-2-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-1-to-3 {
        -webkit-animation-name: from-1-to-3;
                animation-name: from-1-to-3;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-1-to-3 {
            -webkit-animation-name: from-1-to-3-mob;
                    animation-name: from-1-to-3-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-1-to-4 {
        -webkit-animation-name: from-1-to-4;
                animation-name: from-1-to-4;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-1-to-4 {
            -webkit-animation-name: from-1-to-4-mob;
                    animation-name: from-1-to-4-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-1-to-5 {
        -webkit-animation-name: from-1-to-5;
                animation-name: from-1-to-5;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-1-to-5 {
            -webkit-animation-name: from-1-to-5-mob;
                    animation-name: from-1-to-5-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-1-to-6 {
        -webkit-animation-name: from-1-to-6;
                animation-name: from-1-to-6;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-1-to-6 {
            -webkit-animation-name: from-1-to-6-mob;
                    animation-name: from-1-to-6-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-1-to-7 {
        -webkit-animation-name: from-1-to-7;
                animation-name: from-1-to-7;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-1-to-7 {
            -webkit-animation-name: from-1-to-7-mob;
                    animation-name: from-1-to-7-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-1-to-8 {
        -webkit-animation-name: from-1-to-8;
                animation-name: from-1-to-8;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-1-to-8 {
            -webkit-animation-name: from-1-to-8-mob;
                    animation-name: from-1-to-8-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-2-to-0 {
        -webkit-animation-name: from-2-to-0;
                animation-name: from-2-to-0;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-2-to-0 {
            -webkit-animation-name: from-2-to-0-mob;
                    animation-name: from-2-to-0-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-2-to-1 {
        -webkit-animation-name: from-2-to-1;
                animation-name: from-2-to-1;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-2-to-1 {
            -webkit-animation-name: from-2-to-1-mob;
                    animation-name: from-2-to-1-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-2-to-3 {
        -webkit-animation-name: from-2-to-3;
                animation-name: from-2-to-3;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-2-to-3 {
            -webkit-animation-name: from-2-to-3-mob;
                    animation-name: from-2-to-3-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-2-to-4 {
        -webkit-animation-name: from-2-to-4;
                animation-name: from-2-to-4;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-2-to-4 {
            -webkit-animation-name: from-2-to-4-mob;
                    animation-name: from-2-to-4-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-2-to-5 {
        -webkit-animation-name: from-2-to-5;
                animation-name: from-2-to-5;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-2-to-5 {
            -webkit-animation-name: from-2-to-5-mob;
                    animation-name: from-2-to-5-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-2-to-6 {
        -webkit-animation-name: from-2-to-6;
                animation-name: from-2-to-6;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-2-to-6 {
            -webkit-animation-name: from-2-to-6-mob;
                    animation-name: from-2-to-6-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-2-to-7 {
        -webkit-animation-name: from-2-to-7;
                animation-name: from-2-to-7;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-2-to-7 {
            -webkit-animation-name: from-2-to-7-mob;
                    animation-name: from-2-to-7-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-2-to-8 {
        -webkit-animation-name: from-2-to-8;
                animation-name: from-2-to-8;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-2-to-8 {
            -webkit-animation-name: from-2-to-8-mob;
                    animation-name: from-2-to-8-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-3-to-0 {
        -webkit-animation-name: from-3-to-0;
                animation-name: from-3-to-0;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-3-to-0 {
            -webkit-animation-name: from-3-to-0-mob;
                    animation-name: from-3-to-0-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-3-to-1 {
        -webkit-animation-name: from-3-to-1;
                animation-name: from-3-to-1;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-3-to-1 {
            -webkit-animation-name: from-3-to-1-mob;
                    animation-name: from-3-to-1-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-3-to-2 {
        -webkit-animation-name: from-3-to-2;
                animation-name: from-3-to-2;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-3-to-2 {
            -webkit-animation-name: from-3-to-2-mob;
                    animation-name: from-3-to-2-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-3-to-4 {
        -webkit-animation-name: from-3-to-4;
                animation-name: from-3-to-4;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-3-to-4 {
            -webkit-animation-name: from-3-to-4-mob;
                    animation-name: from-3-to-4-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-3-to-5 {
        -webkit-animation-name: from-3-to-5;
                animation-name: from-3-to-5;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-3-to-5 {
            -webkit-animation-name: from-3-to-5-mob;
                    animation-name: from-3-to-5-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-3-to-6 {
        -webkit-animation-name: from-3-to-6;
                animation-name: from-3-to-6;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-3-to-6 {
            -webkit-animation-name: from-3-to-6-mob;
                    animation-name: from-3-to-6-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-3-to-7 {
        -webkit-animation-name: from-3-to-7;
                animation-name: from-3-to-7;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-3-to-7 {
            -webkit-animation-name: from-3-to-7-mob;
                    animation-name: from-3-to-7-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-3-to-8 {
        -webkit-animation-name: from-3-to-8;
                animation-name: from-3-to-8;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-3-to-8 {
            -webkit-animation-name: from-3-to-8-mob;
                    animation-name: from-3-to-8-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-4-to-0 {
        -webkit-animation-name: from-4-to-0;
                animation-name: from-4-to-0;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-4-to-0 {
            -webkit-animation-name: from-4-to-0-mob;
                    animation-name: from-4-to-0-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-4-to-1 {
        -webkit-animation-name: from-4-to-1;
                animation-name: from-4-to-1;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-4-to-1 {
            -webkit-animation-name: from-4-to-1-mob;
                    animation-name: from-4-to-1-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-4-to-2 {
        -webkit-animation-name: from-4-to-2;
                animation-name: from-4-to-2;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-4-to-2 {
            -webkit-animation-name: from-4-to-2-mob;
                    animation-name: from-4-to-2-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-4-to-3 {
        -webkit-animation-name: from-4-to-3;
                animation-name: from-4-to-3;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-4-to-3 {
            -webkit-animation-name: from-4-to-3-mob;
                    animation-name: from-4-to-3-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-4-to-5 {
        -webkit-animation-name: from-4-to-5;
                animation-name: from-4-to-5;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-4-to-5 {
            -webkit-animation-name: from-4-to-5-mob;
                    animation-name: from-4-to-5-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-4-to-6 {
        -webkit-animation-name: from-4-to-6;
                animation-name: from-4-to-6;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-4-to-6 {
            -webkit-animation-name: from-4-to-6-mob;
                    animation-name: from-4-to-6-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-4-to-7 {
        -webkit-animation-name: from-4-to-7;
                animation-name: from-4-to-7;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-4-to-7 {
            -webkit-animation-name: from-4-to-7-mob;
                    animation-name: from-4-to-7-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-4-to-8 {
        -webkit-animation-name: from-4-to-8;
                animation-name: from-4-to-8;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-4-to-8 {
            -webkit-animation-name: from-4-to-8-mob;
                    animation-name: from-4-to-8-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-5-to-0 {
        -webkit-animation-name: from-5-to-0;
                animation-name: from-5-to-0;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-5-to-0 {
            -webkit-animation-name: from-5-to-0-mob;
                    animation-name: from-5-to-0-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-5-to-1 {
        -webkit-animation-name: from-5-to-1;
                animation-name: from-5-to-1;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-5-to-1 {
            -webkit-animation-name: from-5-to-1-mob;
                    animation-name: from-5-to-1-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-5-to-2 {
        -webkit-animation-name: from-5-to-2;
                animation-name: from-5-to-2;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-5-to-2 {
            -webkit-animation-name: from-5-to-2-mob;
                    animation-name: from-5-to-2-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-5-to-3 {
        -webkit-animation-name: from-5-to-3;
                animation-name: from-5-to-3;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-5-to-3 {
            -webkit-animation-name: from-5-to-3-mob;
                    animation-name: from-5-to-3-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-5-to-4 {
        -webkit-animation-name: from-5-to-4;
                animation-name: from-5-to-4;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-5-to-4 {
            -webkit-animation-name: from-5-to-4-mob;
                    animation-name: from-5-to-4-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-5-to-6 {
        -webkit-animation-name: from-5-to-6;
                animation-name: from-5-to-6;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-5-to-6 {
            -webkit-animation-name: from-5-to-6-mob;
                    animation-name: from-5-to-6-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-5-to-7 {
        -webkit-animation-name: from-5-to-7;
                animation-name: from-5-to-7;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-5-to-7 {
            -webkit-animation-name: from-5-to-7-mob;
                    animation-name: from-5-to-7-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-5-to-8 {
        -webkit-animation-name: from-5-to-8;
                animation-name: from-5-to-8;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-5-to-8 {
            -webkit-animation-name: from-5-to-8-mob;
                    animation-name: from-5-to-8-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-6-to-0 {
        -webkit-animation-name: from-6-to-0;
                animation-name: from-6-to-0;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-6-to-0 {
            -webkit-animation-name: from-6-to-0-mob;
                    animation-name: from-6-to-0-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-6-to-1 {
        -webkit-animation-name: from-6-to-1;
                animation-name: from-6-to-1;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-6-to-1 {
            -webkit-animation-name: from-6-to-1-mob;
                    animation-name: from-6-to-1-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-6-to-2 {
        -webkit-animation-name: from-6-to-2;
                animation-name: from-6-to-2;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-6-to-2 {
            -webkit-animation-name: from-6-to-2-mob;
                    animation-name: from-6-to-2-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-6-to-3 {
        -webkit-animation-name: from-6-to-3;
                animation-name: from-6-to-3;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-6-to-3 {
            -webkit-animation-name: from-6-to-3-mob;
                    animation-name: from-6-to-3-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-6-to-4 {
        -webkit-animation-name: from-6-to-4;
                animation-name: from-6-to-4;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-6-to-4 {
            -webkit-animation-name: from-6-to-4-mob;
                    animation-name: from-6-to-4-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-6-to-5 {
        -webkit-animation-name: from-6-to-5;
                animation-name: from-6-to-5;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-6-to-5 {
            -webkit-animation-name: from-6-to-5-mob;
                    animation-name: from-6-to-5-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-6-to-7 {
        -webkit-animation-name: from-6-to-7;
                animation-name: from-6-to-7;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-6-to-7 {
            -webkit-animation-name: from-6-to-7-mob;
                    animation-name: from-6-to-7-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-6-to-8 {
        -webkit-animation-name: from-6-to-8;
                animation-name: from-6-to-8;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-6-to-8 {
            -webkit-animation-name: from-6-to-8-mob;
                    animation-name: from-6-to-8-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-7-to-0 {
        -webkit-animation-name: from-7-to-0;
                animation-name: from-7-to-0;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-7-to-0 {
            -webkit-animation-name: from-7-to-0-mob;
                    animation-name: from-7-to-0-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-7-to-1 {
        -webkit-animation-name: from-7-to-1;
                animation-name: from-7-to-1;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-7-to-1 {
            -webkit-animation-name: from-7-to-1-mob;
                    animation-name: from-7-to-1-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-7-to-2 {
        -webkit-animation-name: from-7-to-2;
                animation-name: from-7-to-2;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-7-to-2 {
            -webkit-animation-name: from-7-to-2-mob;
                    animation-name: from-7-to-2-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-7-to-3 {
        -webkit-animation-name: from-7-to-3;
                animation-name: from-7-to-3;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-7-to-3 {
            -webkit-animation-name: from-7-to-3-mob;
                    animation-name: from-7-to-3-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-7-to-4 {
        -webkit-animation-name: from-7-to-4;
                animation-name: from-7-to-4;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-7-to-4 {
            -webkit-animation-name: from-7-to-4-mob;
                    animation-name: from-7-to-4-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-7-to-5 {
        -webkit-animation-name: from-7-to-5;
                animation-name: from-7-to-5;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-7-to-5 {
            -webkit-animation-name: from-7-to-5-mob;
                    animation-name: from-7-to-5-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-7-to-6 {
        -webkit-animation-name: from-7-to-6;
                animation-name: from-7-to-6;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-7-to-6 {
            -webkit-animation-name: from-7-to-6-mob;
                    animation-name: from-7-to-6-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-7-to-8 {
        -webkit-animation-name: from-7-to-8;
                animation-name: from-7-to-8;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-7-to-8 {
            -webkit-animation-name: from-7-to-8-mob;
                    animation-name: from-7-to-8-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-8-to-0 {
        -webkit-animation-name: from-8-to-0;
                animation-name: from-8-to-0;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-8-to-0 {
            -webkit-animation-name: from-8-to-0-mob;
                    animation-name: from-8-to-0-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-8-to-1 {
        -webkit-animation-name: from-8-to-1;
                animation-name: from-8-to-1;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-8-to-1 {
            -webkit-animation-name: from-8-to-1-mob;
                    animation-name: from-8-to-1-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-8-to-2 {
        -webkit-animation-name: from-8-to-2;
                animation-name: from-8-to-2;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-8-to-2 {
            -webkit-animation-name: from-8-to-2-mob;
                    animation-name: from-8-to-2-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-8-to-3 {
        -webkit-animation-name: from-8-to-3;
                animation-name: from-8-to-3;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-8-to-3 {
            -webkit-animation-name: from-8-to-3-mob;
                    animation-name: from-8-to-3-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-8-to-4 {
        -webkit-animation-name: from-8-to-4;
                animation-name: from-8-to-4;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-8-to-4 {
            -webkit-animation-name: from-8-to-4-mob;
                    animation-name: from-8-to-4-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-8-to-5 {
        -webkit-animation-name: from-8-to-5;
                animation-name: from-8-to-5;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-8-to-5 {
            -webkit-animation-name: from-8-to-5-mob;
                    animation-name: from-8-to-5-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-8-to-6 {
        -webkit-animation-name: from-8-to-6;
                animation-name: from-8-to-6;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-8-to-6 {
            -webkit-animation-name: from-8-to-6-mob;
                    animation-name: from-8-to-6-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images.from-8-to-7 {
        -webkit-animation-name: from-8-to-7;
                animation-name: from-8-to-7;
        -webkit-animation-duration: .5s;
                animation-duration: .5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards; }
        @media (max-width: 992px) {
          .slides .preview-container .preview-images.from-8-to-7 {
            -webkit-animation-name: from-8-to-7-mob;
                    animation-name: from-8-to-7-mob;
            -webkit-animation-duration: .5s;
                    animation-duration: .5s;
            -webkit-animation-timing-function: ease;
                    animation-timing-function: ease;
            -webkit-animation-fill-mode: forwards;
                    animation-fill-mode: forwards; } }
      .slides .preview-container .preview-images img {
        margin: 0 30px;
        width: 262px; }
  .slides .preview-controls {
    width: 400px;
    justify-content: space-around;
    margin-top: 120px;
    position: relative; }
    .slides .preview-controls .control {
      border: 2px solid #C4C4C4;
      width: 12px;
      height: 12px;
      border-radius: 50%; }
      .slides .preview-controls .control:hover {
        cursor: pointer; }
      .slides .preview-controls .control.active {
        background: #FB397D;
        border: 2px solid #FB397D; }
  .slides .phone {
    width: 300px;
    position: absolute;
    z-index: -1; }
  .slides .bg-1,
  .slides .bg-2,
  .slides .bg-3 {
    position: absolute; }
  .slides .bg-1 {
    top: -200px;
    right: 100px; }
  .slides .bg-2 {
    top: 500px;
    right: 800px; }
  .slides .bg-3 {
    bottom: -300px;
    left: 700px; }

.relax {
  position: relative;
  padding: 0 60px; }
  .relax [class*='bg-'] {
    position: absolute; }
  .relax .bg-1-relax {
    left: 0;
    top: -300px; }
  .relax .bg-2-relax {
    top: -255px;
    right: 0; }
  .relax .bg-3-relax {
    top: -282px;
    right: 0; }
  .relax .container {
    z-index: 10;
    justify-content: space-between;
    align-items: center; }
    .relax .container img {
      width: 50%;
      margin-right: 100px; }
    .relax .container h2 {
      font-size: 48px;
      color: #5B32B4;
      margin-bottom: 50px; }
    .relax .container p {
      margin-bottom: 25px; }

@media (max-width: 992px) {
  .main-banner .header-app-name {
    font-size: 16px; }
  .main-banner .header-main-title {
    font-size: 42px; }
  .main-banner .header-main-paragraph {
    font-size: 18px; }
  .main-banner .header-content {
    width: 360px; }
    .main-banner .header-content .download-buttons {
      justify-content: space-between; }
      .main-banner .header-content .download-buttons .header-btn {
        margin-right: 0;
        padding: 5px;
        width: 155px; }
  .features__title {
    font-size: 38px;
    margin-bottom: 50px; }
    .features__title::after {
      width: 70px; }
  .features__container {
    flex-wrap: wrap; }
    .features__container .icon {
      width: 28px; }
    .features__container__card {
      width: 30%;
      margin-top: 15px; }
      .features__container__card .name {
        font-size: 16px; }
      .features__container__card .description {
        font-size: 14px; }
  .slides .preview-container .preview-images {
    width: calc(194px * 9 + 60px * 9);
    -webkit-transform: translateX(calc(194px * 4 + 60px * 4));
            transform: translateX(calc(194px * 4 + 60px * 4)); }
    .slides .preview-container .preview-images img {
      width: 194px; }
  .slides .phone {
    width: 220px; }
  .slides .preview-controls {
    margin-top: 90px;
    width: 300px; }
  .relax .bg-1-relax {
    top: -180px; }
  .relax .container img {
    width: 30vw;
    margin-right: 20px; }
  .relax .container .content {
    width: 60%; }
  .relax .container h2 {
    font-size: 36px; }
  .relax .container p {
    font-size: 14px; } }

@media (max-width: 768px) {
  .main-banner {
    padding: 100px 40px 120px; }
    .main-banner .header-content {
      width: 45%; }
      .main-banner .header-content .download-buttons {
        position: relative;
        right: -120%;
        top: -150px;
        z-index: 20;
        width: 270px; }
        .main-banner .header-content .download-buttons .header-btn {
          margin-right: 10px;
          width: 122px; }
          .main-banner .header-content .download-buttons .header-btn p {
            font-size: 11px; }
          .main-banner .header-content .download-buttons .header-btn .store {
            font-size: 13px; }
          .main-banner .header-content .download-buttons .header-btn img {
            height: 16px; }
  .features {
    height: 600px; }
    .features__container {
      flex-wrap: wrap; }
      .features__container__card {
        width: 45%; }
    .features__bg-1 {
      height: 1200px;
      top: -350px;
      right: -410px; } }

@media (max-width: 576px) {
  .main-banner {
    padding: 100px 20px 120px;
    flex-wrap: wrap; }
    .main-banner .header-app-name {
      top: 20px;
      left: 20px; }
    .main-banner .header-content {
      width: 100%; }
      .main-banner .header-content .download-buttons {
        position: static;
        width: 100%; }
    .main-banner .header-img {
      margin-top: 280px;
      width: 100%; }
    .main-banner .header-main-title {
      text-align: center;
      font-size: 42px; }
    .main-banner .header-main-paragraph {
      font-size: 14px;
      text-align: center; }
  .features {
    padding: 10px;
    padding-bottom: 100px;
    height: 870px; }
    .features__container__card {
      width: 80%; }
    .features__title {
      font-size: 28px; }
      .features__title::after {
        width: 70px; }
    .features__bg-1 {
      height: 1750px;
      top: -380px;
      right: -410px; }
  .slides .preview-controls {
    width: 80vw;
    max-width: 400px; }
  .relax {
    padding: 10px; }
    .relax .container {
      flex-wrap: wrap;
      justify-content: center; }
      .relax .container img {
        width: 100%; }
      .relax .container h2 {
        text-align: center;
        font-size: 28px; }
      .relax .container p {
        text-align: center; } }

