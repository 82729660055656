@for $i from 0 to 9 {
  @for $j from 0 to 9 {
    @if $j != $i {
      @keyframes from-#{$i}-to-#{$j} {
        from {
          transform: translateX(calc(1280px - (#{$i} * (322px)) + 7px));
        } to {
          transform: translateX(calc(1280px - (#{$j} * (322px)) + 7px));
        }
      }
    }
  }
}

@for $i from 0 to 9 {
  @for $j from 0 to 9 {
    @if $j != $i {
      @keyframes from-#{$i}-to-#{$j}-mob {
        from {
          transform: translateX(calc(1016px - (#{$i} * (254px))));
        } to {
          transform: translateX(calc(1016px - (#{$j} * (254px))));
        }
      }
    }
  }
}

.slides {
  background: $second-gradient;
  padding: 250px 0 400px;
  justify-content: center;
  align-items: center;
  z-index: 5;
  
  &__container {
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .preview-container {
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 90;
    
    .preview-images {
      float: left;
      width: calc(262px * 9 + 60px * 9);
      transform: translateX(calc(262px * 4 + 60px * 4));

      @for $i from 0 to 9 {
        @for $j from 0 to 9 {
          @if $i != $j {
            &.from-#{$i}-to-#{$j} {
              @media (max-width: 992px) {
                animation-name: from-#{$i}-to-#{$j}-mob;
                animation-duration: .5s;
                animation-timing-function: ease;
                animation-fill-mode: forwards;
              }
              animation-name: from-#{$i}-to-#{$j};
              animation-duration: .5s;
              animation-timing-function: ease;
              animation-fill-mode: forwards;
            }
          }
        }
      }
      
      img {
        margin: 0 30px;
        width: 262px;
      }
    }
  }
  
  .preview-controls {
    width: 400px;
    justify-content: space-around;
    margin-top: 120px;
    position: relative;
    
    .control {
      border: 2px solid #C4C4C4;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      
      &:hover {
        cursor: pointer;
      }
      
      &.active {
        background: $pink;
        border: 2px solid $pink;
      }
    }
  }
  
  .phone {
    width: 300px;
    position: absolute;
    z-index: -1;
  }

  .bg-1,
  .bg-2,
  .bg-3 {
    position: absolute;
  }

  .bg-1 {
    top: -200px;
    right: 100px;
    // z-index: -1;
  }

  .bg-2 {
    top: 500px;
    right: 800px;

  }
  
  .bg-3 {
    bottom: -300px;
    left: 700px;
  }
}