.bg-purple-gradient {
  background: $purple-gradient;
}

.bg-pink {
  background: $pink;
  color: #fff;
}

.bg-white {
  background: white;
  color: $dark;
}